.radioCustom {
  font-family: $text-medium;
  color: #19282F;
  .ant-radio-wrapper {
    font-size: 13px;
    line-height: 18px;
    align-items: center;
    @media screen and (max-width: 640px) {
      margin-bottom: 10px;
    }
    .ant-radio {
      width: 20px;
      height: 20px;
      top: 0;

      .ant-radio-inner {
        width: 20px;
        height: 20px;

        &::after {
          width: 24px;
          height: 24px;
          margin-top: -12px;
          margin-left: -12px;
        }
      }
    }
  }
}