.wraper-pagination {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 1px;
  font-family: "Roboto-Regular";
  border-top: none;
  .ant-pagination-item {
    min-width: 20px !important;
    &.ant-pagination-item-active {
      border: none;
    }
  }
  .page_changer {
    font-family: "Roboto-Bold";
    color: #19282f;
    cursor: pointer;
    &.active_size {
      color: #1d83e3;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table_common {
  &.primary {
    .ant-table {
      line-height: 1.2;
    }
    .ant-table-thead .ant-table-cell {
      font-family: "Roboto-Medium";
      font-size: 13px;
      background-color: $header-table-color;
      color: #fff;
      padding: 8px;
      vertical-align: top;
      &:not(:last-child) {
        border-right: 1px solid #1d83e3 !important;
      }
      &.border-right-table {
        border-right: 1px solid #1d83e3 !important;
      }

      &.border-bottom-table {
        border-bottom: 1px solid #1d83e3 !important;
      }
    }
    .ant-table-tbody .ant-table-cell {
      vertical-align: top;
    }
  }
  &.normal {
    margin-top: 12px;
    .ant-table-thead .ant-table-cell {
      font-family: $text-bold;
      font-size: 13px;
      line-height: 18px;
      background: #f6f6f6;
      text-align: left;
      padding: 8px;
    }
  }
  &.hover-row {
    .ant-table-cell-row-hover {
      cursor: pointer;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 13px;
      line-height: 15px;
      padding: 8px;
      .checkbox-large {
        .ant-checkbox-wrapper {
          align-items: baseline;
          .ant-checkbox {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.empty_content_table {
  display: flex;
  align-items: center;
  padding: 12px;
  flex-direction: column;
  .empty_img {
    height: 62px;
    width: 60px;
    margin-bottom: 7px;
  }
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f6f6f6;
}
.table-thongkebaocao{
  .ant-table-thead>tr>th {
    border-bottom: 1px solid #1d83e3 !important;
  }
  .ant-table-thead .ant-table-cell {
    border-right: 1px solid #1d83e3 !important;
  }
}
.table-theodoigiamsat {
  .ant-table-thead {
    .ant-table-cell {
      border-bottom: unset !important;
    }
  }
}

.table-custom-cell {
  .ant-table-thead {
    .ant-table-cell {
      padding: 8px !important;
    }
  }
}
