body {
  font-family: $text-regular;
}

.icon-sidebar {
  font-size: 20px !important;
}

.button-printer {
  color: #50AE55;
  background-color: #FFFFFF;
  border: 1px solid #E1E8ED;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #50ae5586;
    color: #FFFFFF;
    border: 1px solid #E1E8ED;
  }
}

.filter-wrapper {
  background: #fff;
  padding: 12px 16px;
  border-bottom: 1px solid #19282f1c;
}

.table-wrapper {
  padding: 16px;
  background: #fff;
  box-shadow: 0px 1px 2px rgb(38 50 69 / 8%);
}

// Label
.ant-form-item-label {
  font-family: 'Roboto-Medium';
  padding-bottom: 2px !important;

  .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &::before {
      margin-left: 5px;
    }

    &::after {
      content: unset;
    }
  }
}

.tt-label {
  font-family: 'Roboto-Medium';
}

.ant-table-column-sorter {
  display: none;
}

// TreeSelect
.ant-select-tree {
  font-family: $text-regular;
  margin-top: 4px;
}

.ant-select-tree-switcher {
  align-self: center;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  padding: 6px 4px;
  font-size: 13px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: transparent;
  color: #228EF5;
  font-weight: bold;
}

// Modal
.tra-cuu-modal {
  .ant-modal-header {
    background-color: #F5F5F5;

    .ant-modal-title {
      font-family: 'Montserrat-semibold';
      font-size: 17px;
      text-transform: uppercase;
    }
  }

  .ant-modal-body {
    max-height: 650px;
    overflow-y: auto;
    padding: 0;
    background-color: #F5F5F5;

    .wrap-modal-content {
      background: #fff;
      padding: 13px 24px;
    }
  }
}

.radio-tracuu {}

.button-search-tracu {
  margin-left: 15px;
  width: 40px;
  margin-top: 24px;
  display: flex;
  background: #50AE55;
  height: 37px;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background: #50AE55;
    color: #fff;
  }
}

// Title
.title-orange {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #E88C3A;
}

// Tag
.tag-common {
  height: 26px;
  width: 140px;
  border-radius: 100px;
  line-height: 26px;
  padding: 0 4px;
  border: none;
  margin: 0;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto-Medium";

  &.primary {
    color: #208DF4;
    background: rgba(32, 141, 244, 0.12);
    ;
  }

  &.processing {
    color: #27C2C1;
    background: rgba(39, 194, 193, 0.12);
  }

  &.solving {
    color: #325AE7;
    background: rgba(50, 90, 231, 0.12);
  }

  &.solved {
    color: #7137CE;
    background: rgba(113, 55, 206, 0.12);
  }

  &.success {
    color: #57C22D;
    background: rgba(87, 194, 45, 0.12);
  }

  &.tracking {
    color: #C24D4D;
    background: rgba(194, 77, 77, 0.12);
    ;
  }

  &.danger {
    color: #F88C2C;
    background: rgba(248, 140, 44, 0.1);
  }
}

.itt-back::before,
.itt-continue::before {
  display: block;
  font-size: 10px;
}

.ant-modal-centered {
  .ant-modal-footer {
    text-align: center;
  }
}

.flex-message {
  .ant-message-notice-content {
    .ant-message-custom-content {
      display: flex;
      align-items: center;

      .anticon {
        position: unset;
      }
    }
  }
}

.text-home {
  font-family: 'Roboto-Regular';
  color: #19282F;
  font-weight: 400;
}

.thongkebaocao__search {
  .ant-form-item-row {
    flex-wrap: nowrap;
  }

  &--selecttree {
    .ant-select-tree-checkbox-inner {
      width: 20px;
      height: 20px;
    }

    .ant-select-tree-checkbox {
      margin: 8px 8px 0 0;
    }
  }
}

.ql-container {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #fff;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.ant-table-row-expand-icon {
  margin-top: 0 !important;
}

.ant-table-row-expand-icon {
  transform: scale(1.2);
}