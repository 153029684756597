.tiepdinhki {
  &__icon-save {
    border: 1px solid #E1E8ED;
    border-radius: 3px;
    padding: 8px;
    color: #228EF5;
  }

  &__icon-delete {
    border: 1px solid #E1E8ED;
    border-radius: 3px;
    padding: 8px;
    color: #19282F;
    opacity: 0.5;
  }

  &__dangxuly {
    padding: 6px 16px;
    color: #208DF4;
    background: rgba(32, 141, 244, 0.12);
    border-radius: 100px;
    display: inline-block;
  }
  &__hoanthanh {
    padding: 6px 16px;
    background: rgba(28, 183, 133, 0.12);
    border-radius: 100px;
    display: inline-block;
    color: #1CB785;
  }
}