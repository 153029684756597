//---------------------------------------------
// Font family
//---------------------------------------------
$text-bold: 'Roboto-Bold';
$text-regular: 'Roboto-Regular';
$text-medium: 'Roboto-Medium';
$text-light: 'Roboto-Light';
$text-black: 'Roboto-Black';
$text-thin: 'Roboto-Thin';
$heading-font: 'Montserrat-semibold';

//---------------------------------------------
// Color
//---------------------------------------------
$primary-color: #228EF5;
$orange-color-1: #E88C3A;
$light-black-color: #19282F;
$gray-color: #8C9497;
$green-color-1: #50AE55;
$green-color-2: #1CB785;
$orange-color-2: #FD4923;
$text-color-primary: #19282F;
$header-table-color: #208DF4;
