.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
  min-height: 36px;
  height: auto;
  font-family: $text-regular;
  background-color: #fff;
}

.ant-select-selector {
  font-size: 13px;

  .ant-select-selection-item {
    font-size: 13px;
    display: flex;
    align-items: center;
    white-space: wrap;
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.ant-select-dropdown {
  padding-bottom: 0;
  margin-bottom: 0;
  border-radius: 3px;
  background-color: #fff;
  font-family: $text-regular;
  padding-top: 0;

  .rc-virtual-list-holder-inner {
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  }

  .ant-select-item {
    height: 36px;
    border-bottom: 1px solid #e1e8ed;
    font-size: 13px;

    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-top: 1px solid #e1e8ed;
    }

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  .ant-select-item-option-selected {
    background-color: transparent;
    color: $primary-color;
  }
}
