.upload {
  .upload-action {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .checkbox-large .ant-checkbox-wrapper>span:last-child {
    margin-top: 0;
  }
}