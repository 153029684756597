.button-custom {
  font-family: $text-medium;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  padding: 7px 16px;
  height: auto;
  border-radius: 3px;
  @media screen and (max-width: 640px) {
    justify-content: center;
    width: 100%;
    margin: 0 0 10px 0;
    span {
      white-space: break-spaces;
      text-align: left;
    }
  }
  &.ant-btn-icon-only {
    justify-content: center;
    align-items: center;
  }

  .ant-btn-loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: #fff;
    border-radius: 3px;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
      opacity: 0.8;
    }

    &:focus,
    &:active {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
    }
  }
  &.success {
    background-color: $green-color-1;
    border-color: $green-color-1;
    color: #fff;
    border-radius: 3px;

    &:hover {
      background-color: $green-color-1;
      border-color: $green-color-1;
      opacity: 0.8;
      color: #fff;
    }

    &:focus,
    &:active {
      background-color: $green-color-1;
      border-color: $green-color-1;
      color: #fff;
    }
  }
  &.add {
    background-color: $orange-color-1;
    border-color: $orange-color-1;
    color: #fff;
    border-radius: 3px;

    &:hover {
      background-color: $orange-color-1;
      border-color: $orange-color-1;
      color: #fff;
      opacity: 0.8;
    }

    &:focus,
    &:active {
      background-color: $orange-color-1;
      border-color: $orange-color-1;
      color: #fff;
    }
  }

  &.default {
    background-color: #fff;
    color: #000;
    border: 1px solid #E1E8ED;
    border-radius: 3px;

    &:hover {
      background-color: #fff;
      opacity: 0.8;
    }

    &:focus,
    &:active {
      background-color: #fff;
    }
  }
  &.dashed {
    background-color: #F5F5F5;
    color: #000;
    border: 1px dashed #DADADA;
    border-radius: 3px;

    &:hover {
      background-color: #F5F5F5;
      opacity: 0.8;
      color: $primary-color;
    }

    &:focus,
    &:active {
      background-color: #F5F5F5;
      color: $primary-color;
    }
  }

}
.ant-btn-primary {
  color: #fff;
  border-color: #1890ff !important;
  background: #1890ff !important;
}

.btn-full {
  justify-content: center;
  width: 100%;
}
