.checkbox-large {
  font-family: $text-medium;
  color: #19282F;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 13px;

    .ant-checkbox {
      width: 23px;
      height: 23px;
      min-width: 23px;
      max-width: 23px;
      border-radius: 3px;

      .ant-checkbox-input {
        width: 100%;
        height: 100%;
      }

      .ant-checkbox-inner {
        width: 100%;
        height: 100%;

        &::after {
          width: 9px;
          height: 14px;
          top: 8px;
        }
      }
    }

    >span {
      &:last-child {
        margin-top: 6px;
      }
    }
  }

  .checkbox-search {
    .ant-checkbox {
      margin-top: 0 !important;
    }
  }
}