.ant-input,
.ant-input-number {
  height: 36px;
  font-size: 13px;
  font-family: $text-regular;
  border-radius: 3px;
}

.ant-input-password {
  .ant-input {
    height: 30px;
  }
}

.ant-input-number {
  .ant-input-number-input {
    height: 36px;
  }
  width: 100%;
}

.ant-input-search-button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $green-color-1;
  border-color: $green-color-1;

  &:hover {
    opacity: 0.8;
    background-color: $green-color-1;
    border-color: $green-color-1;
  }
}

textarea.input-textarea {
  height: 78px;
}