.luongchuyendon .ant-table-thead .ant-table-cell {
  color: #19282F;
}

.luongchuyendon .ant-table-thead>tr>th {
  background: #F6F6F6;
  border: 1px solid #EAEAEA;
}

.luongchuyendon .ant-table-thead .ant-table-cell:not(:last-child) {
  border-right: 1px solid #EAEAEA !important;
}