.donvuviec {
  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #E88C3A;
  }
}
