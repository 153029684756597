.tien-do-gq {
  margin-bottom: 12px;
  .ant-card-head {
    background-color: #F6F6F6;
    padding: 0;
    .ant-select-selector {
      background-color: #EFEFEF;
    }
    .ant-card-head-title {
      padding: 12px 15px;
    }
  }

  .ant-card-body {
    padding: 12px 15px;
  }
}