.item-view {
  text-align: left;
  margin-bottom: 12px;

  &__label {
    font-family: $text-bold;
    display: block;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.5;
    margin-bottom: 4px;
  }

  &__data {
    display: block;
    font-size: 14px;
    line-height: 19px;
  }
}