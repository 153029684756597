.form-item-custom {
  margin-bottom: 12px;
  &.mb-0 {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    padding-bottom: 4px;
    margin-right: 14px;
  }

  .ant-form-item-row {
    .ant-form-item-label {
      padding-bottom: 0;
      overflow: unset;
      white-space: normal;
    }
  }

  .ant-form-item-label>label {
    flex-direction: row-reverse;
    font-family: $text-bold;
    font-size: 13px;
    line-height: 18px;

    &::before {
      margin-left: 4px;
      margin-right: 0;
    }

    &::after {
      margin: 0;
    }
  }

  .ant-form-item-explain-error {
    font-family: $text-regular;
    font-size: 13px;
  }
}