.lanhdaotiep {
  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #E88C3A;
    margin-bottom: 6px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #fff;
    color: #19282F;
    font-weight: 600;
    border: 1px solid #EAEAEA;
  }

  .ant-table-thead .ant-table-cell:not(:last-child) {
    border-right: 1px solid #E1E8ED !important;
  }
  &__btn{
    width: 100%;
    display: block;
    text-align: center;
    background-color: #FAFAFA;
    color: #19282F;
    border: 1px solid #E1E8ED;
    i {
      color: #E88C3A;
    }
  }
  &__icontrash{
    color: #19282F;
    opacity: 0.4;
    padding: 8px;
    border: 1px solid #E1E8ED;
  }
}