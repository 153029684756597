.search {
  background-color: #fff;
  padding: 0;
  .search-normal {
    &__left {
      .ant-input-group-addon {
        .ant-input-search-button {
          .anticon-search {
            color: #fff;
          }
          &:active, &:focus {
            background-color: #50AE55;
            border-color: #50AE55;
          }
        }
      }
    }

    &__right {
      font-family: $text-medium;
      font-size: 13px;
      line-height: 15px;
      height: 15px;
      color: $text-color-primary;
      padding: 0;
      cursor: pointer;
      float: right;
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}