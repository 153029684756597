.ant-picker {
  height: 36px;
  font-family: $text-medium;
  font-size: 13px;
  .ant-picker-input {
    input {
      font-size: 13px;
      &::placeholder {
        font-family: $text-regular;
        font-size: 13px;
      }
    }
  }
}
.ant-picker-dropdown {
  font-family: $text-medium;
  font-size: 13px;
  .ant-picker-header {
    font-family: $text-bold;
  }
}